export const FESACheckoutShippingContainer = () => import("./shipping/fesa-checkout-shipping-container")
export const FESACheckoutSingleShippingDetails = () => import("./shipping/fesa-checkout-single-shipping-details")
export const FESACheckoutShippingActions = () => import("./shipping/fesa-checkout-shipping-actions")
export const FESACheckoutPaymentsContainer = () => import("./payment/fesa-checkout-payments-container")
export const FESACheckoutPaymentMethodsContainer = () => import("./payment/fesa-checkout-payment-methods-container")
export const FESACheckoutAccountCredit = () => import("./payment/fesa-checkout-account-credit")
export const FESACheckoutBillingAddress = () => import("./payment/fesa-checkout-billing-address")
export const FESAFiscalRegimeAndCFDI = () => import("./payment/fesa-fiscal-regime-and-cfdi")
export const FESAAccountCreditSummary = () => import("./payment/fesa-account-credit-summary")
export const FESACheckoutPaymentActions = () => import("./payment/fesa-checkout-payment-actions")
export const FESACheckoutOrderConfirmationContainer = () =>
  import("./confirmation/fesa-checkout-order-confirmation-container")
export const FESACheckoutOrderConfirmationTable = () => import("./confirmation/fesa-checkout-order-confirmation-table")
