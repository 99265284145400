export const FESAProductDetailsContainer = () => import("./fesa-product-details-container")
export const FESAProductImageViewer = () => import("./fesa-product-image-viewer")
export const FESAProductLongDescription = () => import("./fesa-product-long-description")
export const FESAProductSpecialDiscount = () => import("./fesa-product-special-discount")

export const FESAProductAddToWishList = () => import("./fesa-product-add-to-wishlist")
export const FESAProductName = () => import("./fesa-product-name")
export const FESAProductPrice = () => import("./fesa-product-price")
export const FESAProductNotifyMe = () => import("./fesa-product-notify-me")
export const FESAProductPrescription = () => import("./fesa-product-prescription")
export const FESAProductSuggestions = () => import("./fesa-product-suggestions")
export const FESAProductMainInformations = () => import("./fesa-product-main-informations")
export const FESAProductAddToCart = () => import("./fesa-product-add-to-cart")
